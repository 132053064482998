import globalServices from '../api/globalServices'
import { download } from '../utils/enerFunctions'

export const state = () => ({
  publicToken: null,
  loggedIn: false,
  user: null,
  requireTrial: null,
  requireAddInterest: null,
  requestedDownload: null,
  requestedDownloadPublicationTitle: null,
  content: null,
  footerContent: null,
  marketingContent: null,
  currentPage: 'homepage',
  countries: [],
  regions: [],
  world: null,
  units: [],
  defaultApplicationPageId: 3,
  defaultChartZoneCodes: ['europe', 'cei', 'amnord', 'aml', 'asie', 'ausasi', 'afriq', 'moy'],
  ignoredZoneCodes: ['ueur', 'g7', 'ameriq', 'ocde', 'bric5'], // Zone only used for backend export
  applicationWidth: null
})

export const getters = {
  publicToken: (state) => {
    return state.publicToken
  },
  loggedIn: (state) => {
    return state.loggedIn
  },
  currentPage: (state) => {
    return state.currentPage
  },
  pages: (state) => {
    if (state.content === null) {
      return []
    }
    return state.content.pages
  },
  pageByRoutePath: (state, getters) => (routePath) => {
    return getters.pages.find(page => ((routePath === '/' + page.urlCategorie + page.url + '.html') || (routePath === '/' + page.url + '.html')))
  },
  defaultPagePath: (state, getters) => {
    const defaultPage = getters.pages.find(page => page.id === state.defaultApplicationPageId)
    return '/' + defaultPage.urlCategorie + defaultPage.url + '.html'
  },
  defaultPageTitle: (state, getters) => {
    const defaultPage = getters.pages.find(page => page.id === state.defaultApplicationPageId)
    return defaultPage.title
  },
  categories: (state, getters) => {
    const returnedArray = []
    const defaultpages = getters.pages.filter(page => page.defaultCategoryPage === true)
    const n = defaultpages.length
    for (let i = 0; i < n; i++) {
      const page = defaultpages[i]
      const categoryName = page.category1
      const category = {
        id: page.id,
        title: page.category1,
        icon: page.icone,
        children: getters.pages.filter(page => page.category1 === categoryName),
        url: '/' + page.urlCategorie + page.url + '.html'
      }
      returnedArray.push(category)
    }
    return returnedArray
  },
  footerContent: (state) => {
    if (state.footerContent === null) {
      return []
    }
    return state.footerContent
  },
  marketingContent: (state) => {
    if (state.marketingContent === null) {
      return []
    }
    return state.marketingContent
  },
  world: (state) => {
    return state.world
  },
  countries: (state) => {
    return state.countries
  },
  regions: (state) => {
    return state.regions
  },
  zones: (state) => {
    const zones = state.countries.concat(state.regions)
    zones.push(state.world)
    return zones
  },
  units: (state) => {
    return state.units
  },
  defaultChartZoneCodes: (state) => {
    return state.defaultChartZoneCodes
  },
  gtmUserData: (state) => {
    let userData = {
      user_id: ''
    }
    if (state.user !== null) {
      userData = {
        user_id: state.user.id,
        firstname: state.user.prenom,
        lastname: state.user.nom,
        email: state.user.mail
      }
    }
    return userData
  },
  requestedDownload: (state) => {
    return state.requestedDownload
  },
  isMobileLayout: (state) => {
    return state.applicationWidth < 992
  }
}

export const actions = {
  async nuxtServerInit (store, context) {
    const locale = context.app.i18n.locale
    await store.dispatch('initZones')
    await store.dispatch('initUnits')
    const pagesContent = () => import('~/data/pages-' + locale + '.json').then(m => m.default || m)
    const footer = require('~/data/footer-' + locale + '.json')
    const marketing = require('~/data/marketing-' + locale + '.json')
    store.commit('INIT_CONTENT', await pagesContent())
    store.commit('INIT_FOOTER_CONTENT', footer)
    store.commit('INIT_MARKETING_CONTENT', marketing)
    await store.dispatch('initAdditionalCountries')
  },

  async initZones ({ state, commit }) {
    const { data } = await globalServices.zones()
    const world = data.filter(zone => zone.nrdCode === 'monde')[0]
    const countries = data.filter(zone => zone.type === 'pays' && state.ignoredZoneCodes.includes(zone.nrdCode) !== true)
    const regions = data.filter(zone => zone.type !== 'pays' && state.ignoredZoneCodes.includes(zone.nrdCode) !== true && zone.nrdCode !== 'monde')
    commit('INIT_WORLD', world)
    commit('INIT_COUNTRIES', countries)
    commit('INIT_REGIONS', regions)
  },
  async initAdditionalCountries ({ commit, getters }) {
    const pages = getters.pages
    const additionalCountryCodes = pages.reduce(function (accumulateur, valeurCourante) {
      if (valeurCourante.map !== undefined && valeurCourante.map.additionalsCountriesCodeNRDMap !== '') {
        accumulateur = accumulateur.concat(valeurCourante.map.additionalsCountriesCodeNRDMap.split(','))
      }
      return accumulateur
    }, [])
    const { data } = await globalServices.zonesByNrdCode(additionalCountryCodes)
    commit('ADD_COUNTRIES', data)
  },
  async initUnits ({ commit }) {
    const { data } = await globalServices.units()
    commit('INIT_UNITS', data)
  },
  clearRequestedDownload ({ commit }) {
    commit('SET_REQUESTED_DOWNLOAD', null)
    commit('SET_REQUESTED_DOWNLOAD_PUBLICATION_TITLE', null)
  },
  setRequestedDownload ({ commit }, { fileName, publicationTitle }) {
    commit('SET_REQUESTED_DOWNLOAD', fileName)
    commit('SET_REQUESTED_DOWNLOAD_PUBLICATION_TITLE', publicationTitle)
  },
  async updateLoggedIn ({ commit, dispatch, state }, value) {
    commit('SET_LOGGED_IN', value)
    if (value === true) {
      const user = await this.$customer.getContact()
      commit('SET_USER', user)
      if (state.requestedDownload !== null) {
        const fileName = state.requestedDownload
        const publicationTitle = state.requestedDownloadPublicationTitle
        await dispatch('downloadFile', { fileName, publicationTitle })
      }
    } else {
      commit('SET_USER', null)
    }
  },
  async downloadFile ({ commit, dispatch, state }, { fileName, publicationTitle }) {
    download(await this.$yearbook.getXlsxFile(fileName), fileName)
    this.$gtm.push({
      event: 'download_free_publication',
      publication_name: fileName,
      publication_type: 'yearbook',
      user_logged: 'yes'
    })
    // save publication in erp
    this.$customer.savePublication(publicationTitle, this.$i18n.locale)
    dispatch('clearRequestedDownload')
    // TODO restore when subscription part done
    // create trial if needed
    if (state.requireTrial !== false) {
      const activeSubscriptions = await this.$customer.getActiveSubscriptions()
      const gedSubscription = activeSubscriptions.find(subscription => subscription.serviceCode === 'gls')
      if (gedSubscription === undefined) {
        const activeTrials = await this.$customer.getActiveTrials()
        const gedTrial = activeTrials.find(trial => trial.serviceCode === 'gls')
        if (gedTrial === undefined) {
          this.$customer.createTrial('gls', 'Yearbook')
        } else {
          commit('SET_REQUIRE_TRIAL', false)
        }
      } else {
        commit('SET_REQUIRE_TRIAL', false)
      }
    }
  },
  updateApplicationWidth ({ commit }, width) {
    commit('UPDATE_APPLICATION_WIDTH', width)
  }
}

export const mutations = {
  INIT_PUBLIC_TOKEN (state, token) {
    state.publicToken = token
  },
  INIT_CONTENT (state, content) {
    state.content = content
  },
  INIT_FOOTER_CONTENT (state, footer) {
    if (footer.Statistics.elements.length === 0) {
      const defaultPages = state.content.pages.filter(page => page.displayInFooter === true)
      const n = defaultPages.length
      for (let i = 0; i < n; i++) {
        const page = defaultPages[i]
        const category = {
          intitule: page.title,
          url: '/' + page.urlCategorie + page.url + '.html'
        }
        footer.Statistics.elements.push(category)
      }
    }
    state.footerContent = footer
  },
  INIT_MARKETING_CONTENT (state, content) {
    state.marketingContent = content
  },
  INIT_COUNTRIES (state, countries) {
    state.countries = countries
  },
  ADD_COUNTRIES (state, countries) {
    state.countries = state.countries.concat(countries)
  },
  INIT_REGIONS (state, regions) {
    state.regions = regions
  },
  INIT_WORLD (state, world) {
    state.world = world
  },
  INIT_UNITS (state, units) {
    state.units = units
  },
  SET_REQUESTED_DOWNLOAD (state, value) {
    state.requestedDownload = value
  },
  SET_REQUESTED_DOWNLOAD_PUBLICATION_TITLE (state, value) {
    state.requestedDownloadPublicationTitle = value
  },
  SET_LOGGED_IN (state, value) {
    state.loggedIn = value
    if (value === false) {
      state.requireTrial = null
    }
  },
  SET_USER (state, value) {
    state.user = value
  },
  SET_REQUIRE_TRIAL (state, value) {
    state.requireTrial = value
  },
  UPDATE_APPLICATION_WIDTH (state, value) {
    state.applicationWidth = value
  }
}
