
export default {
  name: 'PasswordForm',
  data () {
    return {
      errorMessage: '',
      form: {
        email: '',
        service: 'yearbook'
      }
    }
  },
  methods: {
    async userForgotPassword () {
      this.errorMessage = ''
      try {
        await this.$customer.sendForgotPassword(this.form.email, this.form.service)
        this.$bvModal.hide('password-modal')
      } catch (err) {
        this.errorMessage = this.$i18n.t('modal.login.invalidCredential')
      }
    }
  }
}
