import locale9ec66352 from '../../lang/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.json","name":"English","domain":"yearbook.enerdata.net"},{"code":"es","iso":"es","file":"es.json","name":"Spain","domain":"datos.enerdata.net"},{"code":"de","iso":"de","file":"de.json","name":"German","domain":"energiestatistik.enerdata.net"},{"code":"ja","iso":"ja","file":"ja.json","name":"Japanese","domain":"yearbook.enerdata.jp"},{"code":"ko","iso":"ko","file":"ko.json","name":"Korean","domain":"yearbook.enerdata.co.kr"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "yearbook.enerdata.net",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.json","name":"English","domain":"yearbook.enerdata.net"},{"code":"es","iso":"es","file":"es.json","name":"Spain","domain":"datos.enerdata.net"},{"code":"de","iso":"de","file":"de.json","name":"German","domain":"energiestatistik.enerdata.net"},{"code":"ja","iso":"ja","file":"ja.json","name":"Japanese","domain":"yearbook.enerdata.jp"},{"code":"ko","iso":"ko","file":"ko.json","name":"Korean","domain":"yearbook.enerdata.co.kr"}],
  localeCodes: ["en","es","de","ja","ko"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "a",
  17: "n",
  18: "g",
  19: "/",
  20: "e",
  21: "n",
  22: ".",
  23: "j",
  24: "s",
  25: "o",
  26: "n",
  27: "\"",
  28: ",",
  29: "\"",
  30: "e",
  31: "s",
  32: ".",
  33: "j",
  34: "s",
  35: "o",
  36: "n",
  37: "\"",
  38: ":",
  39: "\"",
  40: ".",
  41: ".",
  42: "/",
  43: "l",
  44: "a",
  45: "n",
  46: "g",
  47: "/",
  48: "e",
  49: "s",
  50: ".",
  51: "j",
  52: "s",
  53: "o",
  54: "n",
  55: "\"",
  56: ",",
  57: "\"",
  58: "d",
  59: "e",
  60: ".",
  61: "j",
  62: "s",
  63: "o",
  64: "n",
  65: "\"",
  66: ":",
  67: "\"",
  68: ".",
  69: ".",
  70: "/",
  71: "l",
  72: "a",
  73: "n",
  74: "g",
  75: "/",
  76: "d",
  77: "e",
  78: ".",
  79: "j",
  80: "s",
  81: "o",
  82: "n",
  83: "\"",
  84: ",",
  85: "\"",
  86: "j",
  87: "a",
  88: ".",
  89: "j",
  90: "s",
  91: "o",
  92: "n",
  93: "\"",
  94: ":",
  95: "\"",
  96: ".",
  97: ".",
  98: "/",
  99: "l",
  100: "a",
  101: "n",
  102: "g",
  103: "/",
  104: "j",
  105: "a",
  106: ".",
  107: "j",
  108: "s",
  109: "o",
  110: "n",
  111: "\"",
  112: ",",
  113: "\"",
  114: "k",
  115: "o",
  116: ".",
  117: "j",
  118: "s",
  119: "o",
  120: "n",
  121: "\"",
  122: ":",
  123: "\"",
  124: ".",
  125: ".",
  126: "/",
  127: "l",
  128: "a",
  129: "n",
  130: "g",
  131: "/",
  132: "k",
  133: "o",
  134: ".",
  135: "j",
  136: "s",
  137: "o",
  138: "n",
  139: "\"",
  140: "}",
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'es.json': () => import('../../lang/es.json' /* webpackChunkName: "lang-es.json" */),
  'de.json': () => import('../../lang/de.json' /* webpackChunkName: "lang-de.json" */),
  'ja.json': () => import('../../lang/ja.json' /* webpackChunkName: "lang-ja.json" */),
  'ko.json': () => import('../../lang/ko.json' /* webpackChunkName: "lang-ko.json" */),
}
