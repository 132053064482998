
import { mapActions, mapGetters } from 'vuex'
import _throttle from 'lodash/throttle'
import FooterColumn from '../components/layout/FooterColumn'
import EnerTopMenu from '../components/ui/enerTopMenu'
import EnerMobileMenu from '../components/ui/enerMobileMenu'
import LoginForm from '../components/ui/loginForm'
import PasswordForm from '../components/ui/passwordForm'
import EnerSocialFloatingButton from '../components/ui/enerSocialFloatingButton'
export default {
  components: { EnerSocialFloatingButton, PasswordForm, LoginForm, EnerMobileMenu, EnerTopMenu, FooterColumn },
  computed: {
    ...mapGetters({
      pageContent: 'page/pageContent',
      footerContent: 'footerContent',
      categories: 'categories',
      loggedIn: 'loggedIn',
      publicToken: 'publicToken',
      isMobileLayout: 'isMobileLayout'
    })
  },
  created () {
    this.$nuxt.$on('download', (type) => {
      switch (type) {
        case 'global':
          this.handleDownloadGlobalFile()
          break
        case 'page':
          this.handleDownloadPageFile()
          break
      }
    })
  },
  mounted () {
    this.updateLoggedIn(this.$cookies.get('user_token') !== undefined)
    window.addEventListener('resize', this.resizeHandler)
    this.updateApplicationWidth(window.innerWidth)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapActions({
      updateApplicationWidth: 'updateApplicationWidth',
      updateLoggedIn: 'updateLoggedIn',
      setRequestedDownload: 'setRequestedDownload',
      clearRequestedDownload: 'clearRequestedDownload',
      downloadFile: 'downloadFile'
    }),
    resizeHandler: _throttle(function () {
      this.updateApplicationWidth(window.innerWidth)
    }, 200),
    handleMobileLoginClick () {
      this.$refs.menuSidebar.hide()
      this.clearRequestedDownload()
      this.$bvModal.show('login-modal')
    },
    login () {
      this.clearRequestedDownload()
      this.$bvModal.show('login-modal')
    },
    logout () {
      this.$cookies.remove('user_token', { path: '/' })
      this.updateLoggedIn(false)
      this.$axios.setToken(this.publicToken, 'Bearer')
    },
    async handleDownloadGlobalFile () {
      const fileName = 'Enerdata_Energy_Statistical_Yearbook_' + this.$config.publicationYear + '.xlsx'
      const publicationTitle = 'Yearbook ' + this.$config.publicationYear
      if (this.loggedIn === true) {
        await this.downloadFile({ fileName, publicationTitle })
      } else {
        this.setRequestedDownload({ fileName, publicationTitle })
        this.$bvModal.show('login-modal')
      }
    },
    async handleDownloadPageFile () {
      if (this.pageContent !== null) {
        const fileName = this.pageContent.downloadFileName + '.xlsx'
        const publicationTitle = 'Yearbook ' + this.$config.publicationYear + ' - Item Export'
        if (this.loggedIn === true) {
          await this.downloadFile({ fileName, publicationTitle })
        } else {
          this.setRequestedDownload({ fileName, publicationTitle })
          this.$bvModal.show('login-modal')
        }
      }
    }
  }
}
