import qs from 'qs'

let axios
let url

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  async getToken (username, password) {
    const response = await axios.post(url + '/login_check', qs.stringify({ username, password }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return response.data.token
  },
  async getContact () {
    const response = await axios.get(url + '/get_contact')
    return response.data
  },
  async sendForgotPassword (mail, service) {
    await axios.post(url + '/global/send_forgot_password_form', qs.stringify({ mail, service }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  async updatePassword (id, token, newPassword) {
    await axios.post(url + '/internal/update_password_for_id_with_token', qs.stringify({ id, token, newPassword }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  async getListCountries (language) {
    const response = await axios.get(url + '/internal/get_list_country/' + language)
    return response.data
  },
  async getListOptions (selectName, language) {
    const response = await axios.get(url + '/internal/get_list_select/' + selectName + '/' + language)
    return response.data
  },
  async getCaptcha (formId) {
    const response = await axios.get(url + '/internal/get_captcha/' + formId)
    return response.data
  },
  async sendRegister (form) {
    await axios.post(url + '/global/send_register_form', qs.stringify(form), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  async activateUser (mailHash) {
    const response = await axios.get(url + '/internal/activate_user_by_mail/Yearbook/' + mailHash)
    return response.data.token
  },
  async savePublication (title, language) {
    await axios.post(url + '/save_publication', qs.stringify({ title, language }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  async getActiveSubscriptions () {
    const response = await axios.get(url + '/get_active_subscriptions')
    return response.data
  },
  async getActiveTrials () {
    const response = await axios.get(url + '/get_active_trials')
    return response.data
  },
  async createTrial (serviceCode, origin) {
    const response = await axios.post(url + '/add_trial_with_notification', qs.stringify({
      serviceCode,
      origin
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return response.data
  }
}
